import scrollIntoView from 'scroll-into-view-if-needed';

export default function scrollToRef(ref, customOptions) {
  const options = {
    scrollMode: 'if-needed',
    behavior: 'smooth',
    block: 'center',
    ...customOptions,
  };

  scrollIntoView(ref.current, options);
}
