export const SizeTypes = {
  BOTTOM_SIZE: 'bottomSize',
  TOP_SIZE: 'topSize',
  BRA_SIZE: 'braSize',
};

export const apparelOptions = new Set([
  SizeTypes.BOTTOM_SIZE,
  SizeTypes.BRA_SIZE,
  SizeTypes.TOP_SIZE,
]);
