import React, { useMemo } from 'react';

import config from 'config';

import StyledSelect from '~/shared/components/StyledSelect';
import {
  useMarketingDisposition,
  useReferrerOptions,
} from '~/techstyle-shared/react-marketing';

const scrubsCustomerReferralList = config.get('public.HDYH.scrubs');

export default function HowDidYouHearDropdown(props) {
  const { experience } = useMarketingDisposition();
  const customerReferrerList =
    experience === 'scrubs' ? scrubsCustomerReferralList : '';
  // Note: This is not a quiz so it does not use useHDYHOptions
  const options = useReferrerOptions({ customerReferrerList });
  const DEFAULT_VALUE = { value: '', label: '' };

  const dropdownOptions = useMemo(() => {
    return options.map((option) => ({
      value: option.referrerId.toString(),
      label: option.label,
    }));
  }, [options]);

  return (
    <StyledSelect {...props}>
      <option {...DEFAULT_VALUE} />
      {dropdownOptions.map(({ value, label }) => (
        <option value={value} key={label}>
          {label}
        </option>
      ))}
    </StyledSelect>
  );
}
