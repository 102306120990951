/**
 * @function camelCaseToTitleCaseWithSpaces
 * @description Converts camel case string to title case
 * @param {string} toConvert String to convert from camel case to snake case
 * @returns {string} A snake case string
 */
export function camelCaseToTitleCaseWithSpaces(toConvert) {
  const spaceDelimitedString = toConvert.replace(/([A-Z])/g, ' $1');
  return (
    spaceDelimitedString.charAt(0).toUpperCase() + spaceDelimitedString.slice(1)
  );
}
