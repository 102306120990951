import { useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';

import { Router } from '~/techstyle-shared/next-routes';

import {
  localStorageItemStatus,
  setLocalStorageItemWithExpiry,
  getLocalStorageItemWithExpiry,
} from './localStorageHelper';

export default function usePersistMpid() {
  const router = useRouter();
  const { asPath, query } = router;

  const persistedMpid = useMemo(
    () => getLocalStorageItemWithExpiry('mpid'),
    []
  );

  const persistedMpidList = useMemo(
    () => getLocalStorageItemWithExpiry('mpid_list'),
    []
  );

  useEffect(() => {
    // if mpid is in the url query, only add it to local storage if not already added
    if (persistedMpid === localStorageItemStatus.DOES_NOT_EXIST && query.mpid) {
      setLocalStorageItemWithExpiry('mpid', query.mpid, 3600 * 1000);
    }
  }, [persistedMpid, query]);

  useEffect(() => {
    // if mpid is in the url query, only add it to local storage if not already added
    if (
      persistedMpidList === localStorageItemStatus.DOES_NOT_EXIST &&
      query.mpid_list
    ) {
      setLocalStorageItemWithExpiry('mpid_list', query.mpid_list, 3600 * 1000);
    }
  }, [persistedMpidList, query]);

  useEffect(() => {
    // if local storage mpid is expired, remove it from url
    if (persistedMpid === localStorageItemStatus.EXPIRED) {
      delete query.mpid;
      Router.replace({
        pathname: asPath,
        query,
      });
    } else if (persistedMpid && !query.mpid) {
      // otherwise we persist mpid to the url query
      Router.replace({
        pathname: asPath,
        query: { ...query, mpid: persistedMpid },
      });
    }
  }, [asPath, persistedMpid, query]);

  useEffect(() => {
    // if local storage mpid is expired, remove it from url
    if (persistedMpidList === localStorageItemStatus.EXPIRED) {
      delete query.mpid_list;
      Router.replace({
        pathname: asPath,
        query,
      });
    } else if (persistedMpidList && !query.mpid_list) {
      // otherwise we persist mpid to the url query
      Router.replace({
        pathname: asPath,
        query: { ...query, mpid_list: persistedMpidList },
      });
    }
  }, [asPath, persistedMpidList, query]);

  return null;
}
