import React, { useMemo } from 'react';

import config from 'config';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { createDataAutoTagString } from '~/builder/components/header/utils/createDataAutoTagString';
import { sizeChartDataAllCollections } from '~/shared/utils/sizeConstants';
import {
  Button,
  Collapsible,
  desktop,
  mobile,
} from '~/techstyle-shared/react-components';

import { apparelOptions, SizeTypes } from '../constants';
import { useSizeSelectionContext } from '../context';

const Content = styled.div`
  ${mobile`
    display: flex;
    justify-content: center;
  `}

  ${({ theme }) =>
    theme.sizeSelectionExpansionPanel.sizeSelectionContent.content};
`;

const SizeBlock = styled(Button).attrs({ variant: 'unstyled' })`
  font-size: 16px;
  font-family: inherit;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: center;
  background: none;
  cursor: pointer;

  display: block;
  margin: 0;

  ${({ theme }) =>
    theme.sizeSelectionExpansionPanel.sizeSelectionContent.sizeButton};
`;

const SizingGrid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${desktop`
    width: 89px;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  `}
`;

/**
 * @function getNextPanelToOpen
 * @description Determines the next panel that should open after a size selection is made
 * @param {SizeTypes} currentPanel The currently opened size selection panel
 * @param {Boolean} hasBraSizeSelection Whether to support bra size selection
 * @returns {SizeTypes|null} The next panel that should be open dependent on the business logic
 * behind `hasBraSizeSelection`. If `currentPanel` is `SizeTypes.TOP_SIZE`, then return `null`
 * as that is the last possible panel for all brands
 */
function getNextPanelToOpen({ currentPanel, hasBraSizeSelection }) {
  if (currentPanel === SizeTypes.BOTTOM_SIZE) {
    return hasBraSizeSelection && apparelOptions.has(SizeTypes.BRA_SIZE)
      ? SizeTypes.BRA_SIZE
      : SizeTypes.TOP_SIZE;
  }

  return currentPanel === SizeTypes.BRA_SIZE ? SizeTypes.TOP_SIZE : null;
}

/**
 * @function getNumericSizeBySizeLabel
 * @description Gets numeric size equivalent for a given size label
 * @param {Object} sizeChart Collection of sizes for the given product type
 * @param {string} sizeLabel The stringified size
 * @returns {string} The stringified numeric size for a given size
 */
export function getNumericSizeBySizeLabel({ sizeChart, sizeLabel }) {
  const sizes = Object.entries(sizeChart);

  const found = sizes
    .map(([value, size]) => ({ value, size }))
    .find((s) =>
      s.size
        .split('|')
        .map((s2) => s2.trim())
        .find((v) => v === sizeLabel)
    ).value;

  return found;
}

function SizeSelectionContent({ setSizeOpen, sizeType }) {
  const {
    getSizeChartByType,
    updateSizeSelections,
    sizeSelectionValues,
    hasBraSizeSelection,
  } = useSizeSelectionContext();

  /**
   * @const sizeLabels
   * @description Provides a list of readable sizes
   */
  const sizeLabels = useMemo(() => {
    const useSizeChartDataAllCollections = config.get(
      'public.brand.sizeSelectionExpansionPanel.useSizeChartDataAllCollections'
    );

    if (useSizeChartDataAllCollections) {
      return Object.keys(sizeChartDataAllCollections.US);
    }

    return Object.values(getSizeChartByType(sizeType)).map((s) => {
      const [sizeText] = s.split('|');
      return sizeText.trim();
    });
  }, [getSizeChartByType, sizeType]);

  return (
    <Collapsible.Content>
      <Content
        data-autotag={createDataAutoTagString(
          'size_selection',
          snakeCase(sizeType),
          'grid'
        )}
      >
        <SizingGrid>
          {sizeLabels.map((size, idx) => {
            return (
              <SizeBlock
                key={idx}
                $selected={
                  sizeSelectionValues[sizeType] ===
                  getNumericSizeBySizeLabel({
                    sizeChart: getSizeChartByType(sizeType),
                    sizeLabel: size,
                  })
                }
                onClick={() => {
                  const numericSize = getNumericSizeBySizeLabel({
                    sizeChart: getSizeChartByType(sizeType),
                    sizeLabel: size,
                  });

                  updateSizeSelections({
                    ...sizeSelectionValues,
                    [sizeType]: numericSize,
                  });

                  const nextSizeSelection = getNextPanelToOpen({
                    currentPanel: sizeType,
                    hasBraSizeSelection,
                  });
                  setSizeOpen(nextSizeSelection);
                }}
                data-autotag={createDataAutoTagString(
                  snakeCase(sizeType),
                  size,
                  'button'
                )}
              >
                {size}
              </SizeBlock>
            );
          })}
        </SizingGrid>
      </Content>
    </Collapsible.Content>
  );
}

SizeSelectionContent.propTypes = {
  setSizeOpen: PropTypes.func,
  sizeType: PropTypes.string,
};

SizeSelectionContent.SizingGrid = SizingGrid;

export default SizeSelectionContent;
