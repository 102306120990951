import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Drawer,
  Modal,
  useBreakpoint,
} from '~/techstyle-shared/react-components';

import { isOneTrustAlertBoxOpen } from '../../utils/helpers';
import CloseButton from '../CloseButton';

const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.palette.white};
  display: flex;
  max-width: 100%;
  ${({ $contentWrapperStyles }) => $contentWrapperStyles}
`;

export default function FableticsModal({
  as: ElementType = Modal,
  children,
  closeButton,
  contentWrapperStyles,
  'data-autotag': dataAutotag,
  defaultOpen = false,
  isOpen,
  onEnter,
  onExit,
  showCloseButton = false,
  trigger,
  openDelay,
  ...rest
}) {
  const { desktop: isDesktop } = useBreakpoint();
  const [internalIsOpen, setInternalIsOpen] = useState(
    openDelay ? false : defaultOpen
  );
  if (isOpen == null) {
    isOpen = internalIsOpen;
  }

  useEffect(() => {
    if (openDelay && defaultOpen) {
      setTimeout(() => {
        setInternalIsOpen(true);
      }, openDelay);
    }
  }, [openDelay, defaultOpen]);

  const oneTrustAlertBoxOpen = isOneTrustAlertBoxOpen();

  const handleClose = useCallback(
    (event) => {
      setInternalIsOpen(false);
      if (onExit) {
        onExit(event);
      }
    },
    [onExit]
  );

  const handleOpen = useCallback(() => {
    setInternalIsOpen(true);
  }, []);

  if (trigger) {
    trigger = React.cloneElement(trigger, { onClick: handleOpen });
  }

  if (ElementType == null) {
    ElementType = isDesktop ? Modal : Drawer;
  }

  const modalCloseButton = closeButton ? (
    React.cloneElement(closeButton, {
      onClick: handleClose,
    })
  ) : (
    <CloseButton onClick={handleClose} />
  );

  return (
    <>
      {trigger}
      <ElementType
        isOpen={isOpen}
        onExit={handleClose}
        onEnter={onEnter}
        focusTrapConfig={{
          active: isOpen && oneTrustAlertBoxOpen ? false : undefined,
        }}
        {...rest}
      >
        <ContentWrapper
          $contentWrapperStyles={contentWrapperStyles}
          data-autotag={dataAutotag}
        >
          {children}
          {showCloseButton && modalCloseButton}
        </ContentWrapper>
      </ElementType>
    </>
  );
}

FableticsModal.ContentWrapper = ContentWrapper;

FableticsModal.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  closeButton: PropTypes.element,
  contentWrapperStyles: PropTypes.any,
  'data-autotag': PropTypes.string,
  defaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  openDelay: PropTypes.number,
  showCloseButton: PropTypes.bool,
  trigger: PropTypes.element,
};
