import React, { useState } from 'react';

import { Formik } from 'formik';
import merge from 'lodash/merge';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import HowDidYouHearDropdown from '~/acquisition/components/HowDidYouHearDropdown';
import { trackCompleteRegistration } from '~/shared/actions';
import featureNames from '~/shared/constants/featureFlags';
import { formatUSPhoneNumber } from '~/shared/utils/formatUSPhoneNumber';
import { isLocale } from '~/shared/utils/helpers';
import logger from '~/shared/utils/logger';
import useGender from '~/shared/utils/useGender';
import useHasFeature from '~/shared/utils/useHasFeature';
import { emailAddressIsValid } from '~/shared/utils/validateEmail';
import { useAccountActions } from '~/techstyle-shared/react-accounts';
import { Button, mobile } from '~/techstyle-shared/react-components';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from '~/techstyle-shared/react-intl';
import { useMarketingActions } from '~/techstyle-shared/react-marketing';
import { useDomain } from '~/techstyle-shared/redux-core';

import SignupTextField from './SignupTextField';

const DynamicFormattedMessage = FormattedMessage;

const debug = logger.extend('SignupForm');

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 410px;
  margin-inline: auto;

  // scope some css vars for re-use
  --input-error-color: ${({ theme }) => theme.colors.error};
  --input-border-color: ${({ theme }) => theme.colors.borderLight};
  --input-border-width: 1px;
  --input-border-radius: 12px;
  --input-padding: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
  z-index: 2;
  &:not(:first-child) {
    margin-top: -1px;
  }

  // allows any type of input that has
  // the focus or error state to float
  // above the others, allowing for its own border
  // to not be hidden by the other inputs,
  // since they overlap by 1px
  &:has(:focus) {
    z-index: 3;
  }
  &:has(:user-invalid) {
    z-index: 4;
  }
`;

const Fields = styled.div``;

const EmailField = styled(SignupTextField)``;

const PasswordField = styled(EmailField)``;

const PhoneField = styled(SignupTextField)``;

const HowDidYouHear = styled(HowDidYouHearDropdown)`
  ${HowDidYouHearDropdown.Fieldset} & {
    ${({ isLastField }) =>
      isLastField
        ? css`
            border-radius: 0 0 var(--input-border-radius)
              var(--input-border-radius);
          `
        : css`
            border-radius: 0;
          `}
    line-height: ${24 / 16};
    &,
    &:focus {
      padding: var(--input-padding);
      border-width: var(--input-border-width);
    }
    ${({ value }) =>
      value &&
      css`
        &,
        &:focus {
          padding-block: 21px 11px;
        }
      `}
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.palette.white};
  background-color: var(--input-error-color);
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.02em;
  position: relative;
  z-index: 1;
  margin-top: -24px;
  padding: 33px 9px 9px;
  border-radius: var(--input-border-radius);
  border: var(--input-border-width) solid var(--input-error-color);

  a {
    text-decoration: underline;
  }
`;

const SMSDisclosure = styled.div`
  color: ${({ theme }) => theme.colors.signupFormSmsDisclosure};
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  padding: 12px 0px;

  a {
    text-decoration: underline;
  }

  ${mobile`
    font-size: 10px;
    line-height: ${12 / 10};
    padding-top: 16px;
    padding-bottom: 0px;
  `}
`;

const errorMessages = defineMessages({
  email: {
    id: 'signup.error_email',
    defaultMessage: 'Invalid email address',
  },
  emailExists: {
    id: 'signup.error_email_exists',
    defaultMessage:
      'Looks like that email already has an account with Yitty or Fabletics! Log in to that account to get access to both brands <a href="/login">here</a>.',
  },
  password: {
    id: 'signup.error_password_length',
    defaultMessage: 'Password must be at least 6 characters',
  },
  phone: {
    id: 'signup.error_phone_length',
    defaultMessage: 'Phone must be 10 digits',
  },
  required: {
    id: 'signup.error_required',
    defaultMessage: 'Required',
  },
  general: {
    id: 'signup.error_general_server',
    defaultMessage:
      'Something went wrong. We are working to fix it ASAP. Please try again!',
  },
});

const SubmitButton = styled(Button)`
  margin-top: 16px;
  span {
    ${({ theme }) => theme.promoPickerPopup.signupSubmitButtonText}
  }
`;

const LoadingSpinner = styled.span`
  width: 22px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    white 0deg,
    rgba(196, 196, 196, 0) 360deg
  );
  mask: radial-gradient(6px at 11px 11px, #0000 98%, #000);
  animation: ${keyframes`
    to {
      transform: rotate(1turn);
    }
  `} 1s infinite linear;
`;

const validateForm = ({ values, requirePassword }) => {
  const errors = {};
  if (!values.email) {
    errors.email = errorMessages.required;
  } else if (!emailAddressIsValid(values.email)) {
    errors.email = errorMessages.email;
  }
  if (!values.referrer) {
    errors.referrer = errorMessages.required;
  }
  if (requirePassword && !values.password) {
    errors.password = errorMessages.required;
  } else if (requirePassword && values.password.length < 6) {
    errors.password = errorMessages.password;
  }
  // dashes are part of values.phone
  if (values.phone && values.phone.length !== 12) {
    errors.phone = errorMessages.phone;
  }
  return errors;
};

function getSignupError(error) {
  const { statusCode, errorData } = error;
  if (statusCode >= 500) {
    return errorMessages.general;
  }
  if (statusCode >= 400) {
    if (errorData) {
      const { code } = errorData[0];
      if (code === -32606) {
        return errorMessages.emailExists;
      }
    }
  }
  return errorMessages.general;
}

function SignupForm({
  onSignupSuccess,
  onSignupError,
  signupData = {},
  signupSource = '',
  showPassword: showPasswordProp,
  buttonLabel = (
    <FormattedMessage
      id="signup.submit_button_label"
      defaultMessage="Claim Your Offer"
    />
  ),
  ...rest
} = {}) {
  const accountActions = useAccountActions();
  const dispatch = useDispatch();
  const intl = useIntl();
  const router = useRouter();
  const isPasswordlessSignupEnabled = useHasFeature(
    featureNames.PASSWORDLESS_SIGNUP
  );
  const [signupError, setSignupError] = useState(null);
  const { gender } = useGender();
  const isSMSEnabled = useHasFeature(featureNames.PROMO_PICKER_SMS);
  const { tld } = useDomain();
  const marketingActions = useMarketingActions();
  const isUSDomain = isLocale(['US'], tld);

  const showPassword =
    showPasswordProp === undefined
      ? !isPasswordlessSignupEnabled
      : showPasswordProp;

  const handleFormSubmit = async (values, formik) => {
    setSignupError(null);
    const { email, password, referrer, phone } = values;
    const { setSubmitting } = formik;
    try {
      const externalData =
        typeof signupData === 'function'
          ? signupData({ values, formik })
          : signupData;

      const credentials = {
        email,
        password,
      };

      const details = {
        signup_source: signupSource,
        customer_referrer_id: referrer,
      };

      const payload = merge(externalData, { details }, credentials);

      const session = await accountActions.signUp(payload);
      debug('Signup Success', { payload, session });
      if (signupSource) {
        await dispatch(trackCompleteRegistration({ source: signupSource }));
      }
      debug('phone', phone);
      if (phone) {
        const contactResult = await marketingActions.addContact({
          firstName: '',
          lastName: '',
          email,
          emailLevel: 'all',
          attributes: {},
          signupSourceId: signupSource,
          smsNumber: phone.replace(/\D/g, ''),
          smsLevel: 'all',
          isScrubs: false,
          gender: gender,
        });
        debug('contactResult', contactResult);
      }

      onSignupSuccess && onSignupSuccess({ payload, session });
      router.replace('/');
      setSubmitting(false);
    } catch (error) {
      const {
        action: {
          meta: { responseBody: response },
        },
      } = error;
      const errorMessage = getSignupError(response);
      setSignupError(errorMessage);
      onSignupError && onSignupError({ error, errorMessage, values, formik });
      debug('Signup Error', { errorMessage, error, values, formik });
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', referrer: '' }}
      onSubmit={handleFormSubmit}
      validate={(values) =>
        validateForm({ values, requirePassword: showPassword })
      }
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        submitCount,
      }) => {
        const hasSubmit = submitCount > 0;
        return (
          <Form onSubmit={handleSubmit} noValidate {...rest}>
            <Fields>
              <InputWrapper>
                <EmailField
                  aria-required
                  name="email"
                  type="email"
                  aria-label="email"
                  data-is-first-input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={
                    hasSubmit &&
                    errors.email &&
                    intl.formatMessage(errors.email)
                  }
                  required
                  label={
                    <FormattedMessage
                      id="signup.email_label"
                      defaultMessage="Email *"
                    />
                  }
                  data-autotag="email"
                />
              </InputWrapper>
              {showPassword && (
                <InputWrapper>
                  <PasswordField
                    aria-required
                    name="password"
                    type="password"
                    aria-label="password"
                    onChange={handleChange}
                    value={values.password}
                    error={
                      hasSubmit &&
                      errors.password &&
                      intl.formatMessage(errors.password)
                    }
                    required
                    label={
                      <FormattedMessage
                        id="signup.password_label"
                        defaultMessage="Password (6 characters minimum) *"
                      />
                    }
                    data-autotag="password"
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <HowDidYouHear
                  onChange={handleChange}
                  name="referrer"
                  id="referrer"
                  error={
                    hasSubmit && errors.referrer
                      ? intl.formatMessage(errors.referrer)
                      : null
                  }
                  label={intl.formatMessage({
                    id: 'signup.hdyh_label',
                    defaultMessage: 'How did you hear about us? *',
                  })}
                  value={values.referrer}
                  isLastField={!isSMSEnabled}
                  required
                  data-autotag="referrer_hdyh_dropdown"
                />
              </InputWrapper>
              {isSMSEnabled && (
                <InputWrapper>
                  <PhoneField
                    aria-required="false"
                    name="phone"
                    aria-label="phone"
                    autoComplete="tel"
                    type="tel"
                    data-is-last-input
                    onChange={
                      isUSDomain
                        ? (e) => {
                            const formattedPhone = formatUSPhoneNumber(
                              e.target.value.replace(/\D/g, '').slice(0, 10)
                            );
                            handleChange({
                              target: {
                                name: 'phone',
                                value: formattedPhone,
                              },
                            });
                          }
                        : handleChange
                    }
                    onBlur={handleBlur}
                    variant="standard"
                    value={values.phone}
                    label={intl.formatMessage({
                      id: 'signup.sms_label',
                      defaultMessage: 'Phone Number (optional)',
                    })}
                    error={
                      hasSubmit &&
                      errors.phone &&
                      intl.formatMessage(errors.phone)
                    }
                    data-autotag="phone"
                  />
                </InputWrapper>
              )}
              {hasSubmit && !isValid && (
                <ErrorMessage data-autotag="SignupForm__ErrorMessage">
                  <FormattedMessage
                    id="signup.error_general"
                    defaultMessage="Please fix errors before continuing"
                  />
                </ErrorMessage>
              )}
              {signupError && (
                <ErrorMessage data-autotag="SignupForm__ErrorMessage">
                  <DynamicFormattedMessage
                    id={signupError.id}
                    defaultMessage={signupError.defaultMessage}
                    values={signupError.values || {}}
                  />
                </ErrorMessage>
              )}
            </Fields>
            {isSMSEnabled && (
              <SMSDisclosure>
                <FormattedMessage
                  id="ppp.sms_legal"
                  defaultMessage="By submitting this form, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Fabletics at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. View Terms & Privacy."
                />
              </SMSDisclosure>
            )}
            <SubmitButton
              data-autotag="SignupForm__SubmitButton"
              type="submit"
              disabled={isSubmitting || (hasSubmit && !isValid)}
            >
              {isSubmitting ? <LoadingSpinner /> : buttonLabel}
            </SubmitButton>
          </Form>
        );
      }}
    </Formik>
  );
}

SignupForm.propTypes = {
  buttonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onSignupError: PropTypes.func,
  onSignupSuccess: PropTypes.func,
  showPassword: PropTypes.bool,
  signupData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  signupSource: PropTypes.string,
};

SignupForm.EmailField = EmailField;
SignupForm.PasswordField = PasswordField;
SignupForm.HowDidYouHearDropdown = HowDidYouHearDropdown;
SignupForm.SubmitButton = SubmitButton;

export default SignupForm;
