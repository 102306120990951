import React from 'react';

import { Gender } from '~/techstyle-shared/react-accounts';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import { useMarketingDisposition } from '~/techstyle-shared/react-marketing';

export default function PromoOfferMessage() {
  const { experience, gender } = useMarketingDisposition();

  if (experience === 'scrubs') {
    return (
      <FormattedMessage
        id="ppp.promo_header_scrubs"
        defaultMessage="$19 Scrubs Set"
      />
    );
  }

  if (gender === Gender.MALE) {
    return (
      <FormattedMessage
        id="ppp.promo_header_male"
        defaultMessage="2 for 24 Shorts"
      />
    );
  }

  return <FormattedMessage id="ppp.promo_header" defaultMessage="70% Off" />;
}
