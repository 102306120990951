/**
 * @function createDataAutoTagString
 * @description Takes all the arguments and concatenates them to a single data-auto-tag string
 * @param {string} args - String(s) to be converted to a data-auto-tag string
 * @returns {string} The string converted to a data-auto-tag string
 *
 * @example
 * createDataAutoTagString('example');
 * // returns 'example'
 *
 * @example
 * createDataAutoTagString('example1', 'example2', 'example3');
 * // returns 'example1_example2_example3'
 */
export const createDataAutoTagString = (...args) => {
  if (!args.length) {
    return '';
  }
  return args
    .map((str) => str?.trim()?.toLowerCase()?.replace(/( )+/g, '-'))
    .filter(Boolean)
    .join('_');
};
