import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web/build/player/lottie_light';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isFabletics } from '~/shared/utils/brandNameHelper';
import { useId } from '~/techstyle-shared/react-components';

import giftBoxOpening from './gift_box_opening.json';
import giftBoxOpeningYitty from './gift_box_opening_yitty.json';

const Container = styled.div`
  position: relative;
`;

const GiftBoxOpeningWrapper = styled.div``;

function GiftBoxOpening({
  autoplay = true,
  onComplete = null,
  onEnterFrame = null,
  ...rest
} = {}) {
  const autoId = useId();
  const animation = useRef(null);
  const animationData = isFabletics() ? giftBoxOpening : giftBoxOpeningYitty;

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: document.querySelector(`#${autoId}`),
      animationData,
      renderer: 'svg',
      loop: false,
      autoplay,
    });

    animation.current.addEventListener('complete', () => {
      onComplete && onComplete(animation.current);
    });

    animation.current.addEventListener('enterFrame', () => {
      onEnterFrame && onEnterFrame(animation.current);
    });

    return () => {
      animation.current.destroy();
    };
  }, [autoId, autoplay, onEnterFrame, onComplete, animationData]);

  return (
    <Container {...rest}>
      <GiftBoxOpeningWrapper id={autoId} />
    </Container>
  );
}

GiftBoxOpening.propTypes = {
  autoplay: PropTypes.bool,
  onComplete: PropTypes.func,
  onEnterFrame: PropTypes.func,
};

export default GiftBoxOpening;
