import React from 'react';

import config from 'config';
import capitalize from 'lodash/capitalize';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

export default function LogoIcon({
  color = 'currentColor',
  size = '1em',
  brandName = config.get('public.brand.name'),
  href,
  ...rest
}) {
  const filePath =
    './components' + (href?.includes('/outlet') ? '/outlet' : '');
  const fileName = `${capitalize(brandName)}.js`;
  const getDynamicComponent = (brandFile) =>
    dynamic(() => import(`${filePath}/${brandFile}`));
  const Logo = getDynamicComponent(fileName);

  return <Logo color={color} size={size} {...rest} />;
}

LogoIcon.propTypes = {
  brandName: PropTypes.oneOf(['fabletics', 'yitty']),
  color: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
