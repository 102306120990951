import { useCallback } from 'react';

import { useRouter } from 'next/router';

import featureNames from '~/shared/constants/featureFlags';
import logger from '~/shared/utils/logger';
import useHasFeature from '~/shared/utils/useHasFeature';
import { usePageCount } from '~/techstyle-shared/react-marketing';
import {
  isTruthyQueryParameter,
  useSession,
  useSessionActions,
} from '~/techstyle-shared/redux-core';

const debug = logger.extend('usePromoPicker');

export const PROMO_PICKER_MODAL_PARAMETER = 'promoPicker';
const PROMO_PICKER_MODAL_CLOSED_SESSION_KEY = 'promoPickerModalClosed';
const PROMO_PICKER_MODAL_VIEWED_SESSION_KEY = 'ppp_viewed';

/**
 * @function saveModalClosed
 * @description Set the promo picker modal closed session storage key to true
 * @returns {void}
 */
export const saveModalClosed = () => {
  if (process.browser) {
    sessionStorage.setItem(PROMO_PICKER_MODAL_CLOSED_SESSION_KEY, true);
  }
};

/**
 * @function clearModalClosed
 * @description Clear the promo picker modal closed session storage
 * @returns {void}
 */
export const clearModalClosed = () => {
  if (process.browser) {
    sessionStorage.removeItem(PROMO_PICKER_MODAL_CLOSED_SESSION_KEY);
  }
};

/**
 * @function wasModalClosed
 * @description Check if the promo picker modal was closed
 * @returns {Boolean} True if the modal was closed per session storage
 */
export const wasModalClosed = () =>
  process.browser &&
  sessionStorage.getItem(PROMO_PICKER_MODAL_CLOSED_SESSION_KEY) === 'true';

export default function usePromoPicker() {
  const { pathname, query } = useRouter();
  const { isLoggedIn } = useSession();
  const { pageCount } = usePageCount();
  const sessionActions = useSessionActions();

  /**
   * @var isPromoPickerModalEnabled
   * @description V1 feature flag enabling display of the PPP feature
   */
  const isPromoPickerModalEnabled = useHasFeature(
    featureNames.PROMO_PICKER_MODAL_SIZES
  );

  const saveModalViewed = useCallback(() => {
    sessionActions.updateSessionDetail({
      name: PROMO_PICKER_MODAL_VIEWED_SESSION_KEY,
      value: featureNames.PROMO_PICKER_MODAL_SIZES,
    });
  }, [sessionActions]);

  const isLandingPage = pathname.includes('landing-page');
  const isEnabled = isPromoPickerModalEnabled && !isLoggedIn;
  const isFirstPageView = pageCount === 0;
  const wasClosed = wasModalClosed();

  /**
   * @var isPppSupported
   * @description PPP is supported in the following environments per business criteria:
   * - The user is on a landing page
   * - The user has interacted with the FTV modal by selecting an option
   */
  const isPppSupported =
    isLandingPage ||
    isTruthyQueryParameter(query[PROMO_PICKER_MODAL_PARAMETER]);

  /**
   * @description `showModal` is used to evaluate if the promo picker modal should be shown.
   * This is dependent on the following conditions:
   * - The promo picker feature flag is enabled and the user is not logged in (`isEnabled`)
   * - The modal has not been closed yet (`wasClosed`)
   * - The environment is supportive of the promo picker modal (`isPppSupported`)
   *
   * NOTE: An additional condition is supported at the home page level to show PPP if the user
   * clicks outside of the FTV modal.
   */
  const showModal = isEnabled && !wasClosed && isPppSupported;

  const promoPickerStatus = {
    isEnabled,
    isFirstPageView,
    showModal,
    wasClosed,
    saveModalViewed,
    saveModalClosed,
    clearModalClosed,
  };

  debug(promoPickerStatus);

  return promoPickerStatus;
}
