import React, { useState } from 'react';

import styled from 'styled-components';

import {
  Collapsible,
  CollapsibleGroup,
} from '~/techstyle-shared/react-components';

import SizeSelectionContent from './components/SizeSelectionContent';
import SizeSelectionHeader from './components/SizeSelectionHeader';
import { apparelOptions, SizeTypes } from './constants';
import { useSizeSelectionContext } from './context';

const CollapsibleWrapper = styled.div`
  font-size: 16px;
  line-height: ${20 / 16};

  ${({ theme }) => theme.sizeSelectionExpansionPanel.collapsibleWrapper};
`;

/**
 * @function getInitialSizePanelOpen
 * @description Using the size selection values stored in local storage via `SizeSelectionProvider`,
 * determines which panel should be open by default. This supports if a user refreshes the page or
 * leaves then returns to the size selection component.
 * @param {null|number} bottomSize Users selected bottom size. `null` if yet to be selected, a number
 * representative to the genders size scale if selected.
 * @param {null|number} braSize  Users selected bra size. `null` if yet to be selected, a number
 * representative to the genders size scale if selected.
 * @param {null|number} topSize  Users selected top size. `null` if yet to be selected, a number
 * representative to the genders size scale if selected.
 * @returns {null|SizeTypes} The open panels associated `SizeTypes` enum
 */
function getInitialSizePanelOpen({ bottomSize, braSize, topSize }) {
  if (!bottomSize) {
    return SizeTypes.BOTTOM_SIZE;
  }
  if (!braSize) {
    return SizeTypes.BRA_SIZE;
  }
  if (!topSize) {
    return SizeTypes.TOP_SIZE;
  }
  return null;
}

function SizeSelectionExpansionPanel() {
  const { sizeSelectionValues, hasBraSizeSelection } =
    useSizeSelectionContext();

  const showBottoms = apparelOptions.has(SizeTypes.BOTTOM_SIZE);
  const showBra = hasBraSizeSelection && apparelOptions.has(SizeTypes.BRA_SIZE);
  const showTop = apparelOptions.has(SizeTypes.TOP_SIZE);

  const [sizeOpen, setSizeOpen] = useState(
    getInitialSizePanelOpen(sizeSelectionValues)
  );

  return (
    <CollapsibleGroup allowMultipleOpen={false}>
      {/* BOTTOM START */}
      {showBottoms && (
        <CollapsibleWrapper showBra={showBra}>
          <Collapsible
            data-autotag={
              sizeOpen === SizeTypes.BOTTOM_SIZE
                ? 'collapsible-size-grid-open'
                : 'collapsible-size-grid-closed'
            }
            isOpen={sizeOpen === SizeTypes.BOTTOM_SIZE}
            onOpen={() => setSizeOpen(SizeTypes.BOTTOM_SIZE)}
            onClose={() => setSizeOpen(null)}
          >
            <div>
              <SizeSelectionHeader
                isOpen={sizeOpen}
                sizeType={SizeTypes.BOTTOM_SIZE}
              />

              <SizeSelectionContent
                setSizeOpen={setSizeOpen}
                sizeType={SizeTypes.BOTTOM_SIZE}
              />
            </div>
          </Collapsible>
        </CollapsibleWrapper>
      )}
      {/* BOTTOM END */}

      {/* BRA START */}
      {showBra && (
        <CollapsibleWrapper showBra={showBra}>
          <Collapsible
            data-autotag={
              sizeOpen === SizeTypes.BRA_SIZE
                ? 'collapsible-size-grid-open'
                : 'collapsible-size-grid-closed'
            }
            isOpen={sizeOpen === SizeTypes.BRA_SIZE}
            onOpen={() => setSizeOpen(SizeTypes.BRA_SIZE)}
            onClose={() => setSizeOpen(null)}
          >
            <div>
              <SizeSelectionHeader
                isOpen={sizeOpen}
                sizeType={SizeTypes.BRA_SIZE}
              />

              <SizeSelectionContent
                setSizeOpen={setSizeOpen}
                sizeType={SizeTypes.BRA_SIZE}
              />
            </div>
          </Collapsible>
        </CollapsibleWrapper>
      )}
      {/* BRA END */}

      {/* TOP START */}
      {showTop && (
        <CollapsibleWrapper showBra={showBra}>
          <Collapsible
            data-autotag={
              sizeOpen === SizeTypes.TOP_SIZE
                ? 'collapsible-size-grid-open'
                : 'collapsible-size-grid-closed'
            }
            isOpen={sizeOpen === SizeTypes.TOP_SIZE}
            onOpen={() => setSizeOpen(SizeTypes.TOP_SIZE)}
            onClose={() => setSizeOpen(null)}
          >
            <div>
              <SizeSelectionHeader
                isOpen={sizeOpen}
                sizeType={SizeTypes.TOP_SIZE}
              />

              <SizeSelectionContent
                setSizeOpen={setSizeOpen}
                sizeType={SizeTypes.TOP_SIZE}
              />
            </div>
          </Collapsible>
        </CollapsibleWrapper>
      )}
      {/* TOP END */}
    </CollapsibleGroup>
  );
}

export default SizeSelectionExpansionPanel;
