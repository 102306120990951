import React from 'react';

import styled, { css } from 'styled-components';

import PromoPicker from '~/acquisition/components/PromoPicker';
import usePromoPicker from '~/acquisition/utils/usePromoPicker';
import CloseButton from '~/shared/components/CloseButton';
import FableticsModal from '~/shared/components/FableticsModal/FableticsModal';
import { mobile } from '~/techstyle-shared/react-components';

const PromoPickerCloseButton = styled(CloseButton)`
  position: absolute;
  inset: 9px 9px auto auto;
  padding: 16px;
  ${mobile`
    position: fixed;
    right: 4px;
    /* due to the stacking/position context of the modal, we need to calculate the placement
    using this wild calculation -- <dialog/> save us from this madness */
    @media (min-width: 350px) {
      right: calc((100dvw - 350px) / 2 + 4px);
    }
  `};

  ${({ theme }) => theme.promoPickerPopup.closeButton}
`;

const dialogStyle = css`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.promoPickerModalBackgroundTop} 0%,
    ${({ theme }) => theme.colors.promoPickerModalBackgroundBottom} 60%
  );
  border-radius: 16px;
  padding: 32px;
  max-width: 520px;
  min-height: min(720px, 100dvh - 40px);
  width: 100%;
  /* desktop taller screens should favor the top alignment,
  so we create a bespoke breakpoint related to our modal size */
  @media (min-height: 900px) {
    align-self: flex-start;
    margin-top: 98px;
  }

  ${mobile`
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.promoPickerModalBackgroundTop} 32%,
      ${({ theme }) => theme.colors.promoPickerModalBackgroundBottom} 72%
    );
    align-self: flex-start;
    margin-top: 8px;
    padding: 16px;
    max-width: 350px;
    max-height: calc(100dvh - 32px);
    min-height: min(648px, 100dvh - 32px);
  `}
`;

const contentWrapperStyles = css`
  background: none;
`;

function PromoPickerModal() {
  const { saveModalViewed, saveModalClosed } = usePromoPicker();
  return (
    <FableticsModal
      title="promo_picker_popup"
      dialogStyle={dialogStyle}
      contentWrapperStyles={contentWrapperStyles}
      showCloseButton
      closeButton={
        <PromoPickerCloseButton data-autotag="PromoPickerCloseButton" />
      }
      defaultOpen
      openDelay={1000}
      onEnter={saveModalViewed}
      onExit={saveModalClosed}
    >
      <PromoPicker />
    </FableticsModal>
  );
}

export default PromoPickerModal;
