import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { desktop, mobile } from '~/techstyle-shared/react-components';

const Panel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  ${desktop`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content auto max-content;
    grid-template-areas: 'subheading' 'content' 'actions' 'footer';
  `}
`;

const Subheading = styled.h3`
  grid-area: subheading;
  font-size: 24px;
  line-height: ${39 / 24};
  line-height: 1.1;
  margin-block: 1em;
  ${mobile`
    font-size: 18px;
  `}
`;

const Content = styled.div`
  grid-area: content;
`;

const Actions = styled.div`
  grid-area: actions;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Footer = styled.div`
  margin-top: 16px;
  grid-area: footer;
  align-self: end;

  ${mobile`
    align-self: center;
  `}
`;

function PromoPickerPanelTemplate({ footer, subheading, children, actions }) {
  return (
    <Panel>
      {subheading && <Subheading>{subheading}</Subheading>}
      <Content>{children}</Content>
      {actions && <Actions>{actions}</Actions>}
      {footer && <Footer>{footer}</Footer>}
    </Panel>
  );
}

PromoPickerPanelTemplate.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  subheading: PropTypes.node.isRequired,
};

export default PromoPickerPanelTemplate;
