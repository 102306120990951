import React from 'react';

import config from 'config';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { createDataAutoTagString } from '~/builder/components/header/utils/createDataAutoTagString';
import { camelCaseToTitleCaseWithSpaces } from '~/shared/utils/camelCaseToTitleCaseWithSpaces';
import { Collapsible } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import { SizeTypes } from '../constants';
import { useSizeSelectionContext } from '../context';

import ApparelIcon from './ApparelIcon';

const DynamicFormattedMessage = FormattedMessage;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.sizeSelectionExpansionPanel.sizeSelectionHeader.header};
`;

const HeaderMain = styled(Collapsible.Button)`
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  line-height: ${20 / 16};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

const Edit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  ${({ theme }) =>
    theme.sizeSelectionExpansionPanel.sizeSelectionHeader.editSizeButton};
`;

const HeaderSub = styled.div`
  font-size: 13px;
  font-weight: 450;
  line-height: 1;
  text-align: start;

  margin-top: 4px;
`;

const SizeHeader = styled.span`
  ${({ theme }) =>
    theme.sizeSelectionExpansionPanel.sizeSelectionHeader.sizeHeader};
`;

/**
 * @function getStringifiedSizeByNumeric
 * @description Provides the users selected size from the size chart
 * @param {Object} sizeChart Collection of sizes for the given product type
 * @param {string} selectedNumericSize The users selected numeric size value stringified for the given product type
 * @returns {string} The non-numeric size label for the users selected size
 */
export function getStringifiedSizeByNumeric({
  sizeChart,
  selectedNumericSize,
}) {
  const found = Object.entries(sizeChart)
    .map(([value, size]) => ({ value, size }))
    .find((s) => s.value === selectedNumericSize);

  return found?.size.split('|')[0].trim();
}

function SizeSelectionHeader({ sizeType, isOpen }) {
  const { getSizeChartByType, sizeSelectionValues } = useSizeSelectionContext();
  const showIcons = config.get(
    'public.brand.sizeSelectionExpansionPanel.showIcons'
  );

  return (
    <Header
      $open={isOpen === sizeType && sizeType === SizeTypes.BOTTOM_SIZE}
      data-autotag={createDataAutoTagString(
        'size_selection',
        snakeCase(sizeType),
        'header'
      )}
    >
      <HeaderMain>
        <HeaderContainer>
          {showIcons && <ApparelIcon sizeType={sizeType} />}
          <DynamicFormattedMessage
            id={`quiz.sizing_screen_${snakeCase(sizeType)}_header`}
            defaultMessage={`${camelCaseToTitleCaseWithSpaces(sizeType)} *`}
          />
          <>&#58;</>
          {sizeSelectionValues[sizeType] ? (
            <SizeHeader data-autotag="SizeSelectionExpansionPanel__SizeHeader">
              &nbsp;
              {getStringifiedSizeByNumeric({
                sizeChart: getSizeChartByType(sizeType),
                selectedNumericSize: sizeSelectionValues[sizeType],
              })}
              *
            </SizeHeader>
          ) : (
            <>*</>
          )}
        </HeaderContainer>

        {sizeSelectionValues[sizeType] && isOpen !== sizeType && (
          <Edit data-autotag="SizeSelectionExpansionPanel__Edit">
            <FormattedMessage
              id="quiz.sizing_screen_edit_copy"
              defaultMessage="Edit"
            />
          </Edit>
        )}
      </HeaderMain>
      {isOpen === sizeType && (
        <HeaderSub>
          <DynamicFormattedMessage
            id={`quiz.sizing_screen_${snakeCase(sizeType)}_paragraph`}
            defaultMessage="You can always change these preferences in your profile settings!"
          />
        </HeaderSub>
      )}
    </Header>
  );
}

SizeSelectionHeader.propTypes = {
  isOpen: PropTypes.bool,
  sizeType: PropTypes.string,
};

export default SizeSelectionHeader;
