/**
 * @function formatUSPhoneNumber
 * @description format string into a US phone number with dashes
 * @param {String} phoneNumber input string
 * @returns {String} formatted string
 */
export function formatUSPhoneNumber(phoneNumber) {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Insert dashes after the 3rd and 7th digit
  const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return [match[1], match[2], match[3]].filter(Boolean).join('-');
  }

  return phoneNumber;
}
