import React, { useCallback, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import {
  PromoOfferMessage,
  PromoPickerFormPanels,
  PromoPickerFormViews,
} from '~/acquisition/components/PromoPicker';
import GiftBoxIcon from '~/icons/components/GiftBoxIcon';
import GiftBoxOpening from '~/icons/components/GiftBoxOpening';
import BaseLogoIcon from '~/icons/components/LogoIcon';
import SizeSelectionProvider from '~/shared/components/SizeSelectionExpansionPanel/context/SizeSelectionProvider';
import { isFabletics } from '~/shared/utils/brandNameHelper';
import { createContainerQueryHelpers } from '~/shared/utils/containerQueryHelper';
import logger from '~/shared/utils/logger';
import useHasFeature, { featureNames } from '~/shared/utils/useHasFeature';
import theme from '~/styles/theme';
import {
  mobile,
  Button,
  useBreakpoint,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import { useMarketingDisposition } from '~/techstyle-shared/react-marketing';

import { trackPromoPickerPageView } from '../../actions';

const debug = logger.extend('PromoPicker');

// percentage of the animation that must be completed to trigger the next view
const GIFT_BOX_ANIMATION_THRESHOLD = 50;
const GIFT_BOX_COUNT = 3;
const OPENED_PAUSE_DURATION = 500;
const Views = {
  BOXES: 'Boxes',
  OPENING: 'Opening',
  OPENED: 'Opened',
  CLOSING: 'Closing',
  FORM: 'Form',
};
const INITIAL_VIEW = Views.BOXES;
const VirtualPageNames = {
  INTRO: 'ppp_intro',
  SIZES: 'ppp_sizes',
  SIGNUP: 'ppp_reg',
  LOGIN: 'ppp_login',
};

const buildVirtualPageRoute = (subPageName) => `/promo-picker/${subPageName}/`;

export const { px2cqi, containedSizeStyle, containerStyles } =
  createContainerQueryHelpers({
    containerName: 'promo-picker',
    mobileOptimalWidth: 318,
    desktopOptimalWidth: 456,
  });

const PromoPickerContainer = styled.div`
  ${containerStyles}
  width: 100%;
`;

const PromoPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  position: relative;
  ${({ $view }) =>
    $view === Views.BOXES &&
    css`
      gap: ${px2cqi(62)};
      ${mobile`
        gap: ${px2cqi(48)};
      `}
    `}
`;

const Header = styled.h2`
  text-transform: uppercase;
  text-wrap: balance;
  letter-spacing: -0.02em;

  ${({ theme }) =>
    containedSizeStyle(theme.promoPickerPopup.desktopHeaderFontSize)}
  ${({ theme }) => theme.promoPickerPopup.header}

  ${mobile`
    ${({ theme }) =>
      containedSizeStyle(theme.promoPickerPopup.mobileHeaderFontSize)}
  `}
`;

const SubHeader = styled.h3`
  ${({ theme }) =>
    containedSizeStyle(theme.promoPickerPopup.desktopSubHeaderFontSize)}
  ${({ theme }) => theme.promoPickerPopup.subHeader}

  ${mobile`
    padding-inline: 12px;
    ${({ theme }) =>
      containedSizeStyle(theme.promoPickerPopup.mobileSubHeaderFontSize)}
  `}
`;

const ContentArea = styled.div`
  flex: 1 0 auto;
  width: 100%;
`;

const GiftBoxesWrapper = styled.div`
  display: flex;
  ${containedSizeStyle(10, 'margin-top')}
  ${mobile`
    ${containedSizeStyle(40, 'margin-top')}
  `}
`;

const giftBoxActiveRiseStart = css`
  transform: translateY(0) scale(1);
`;
const giftBoxActiveRiseEnd = css`
  transform: translateY(-12px) scale(1.1);
`;
const giftBoxActiveShadowStart = css`
  filter: drop-shadow(3px 8px 4px transparent);
`;
const giftBoxActiveShadowEnd = css`
  filter: drop-shadow(3px 8px 4px #e2dfd3);
`;
const giftboxActivationAnimation = keyframes`
  0% {
    ${giftBoxActiveRiseStart}
    ${giftBoxActiveShadowStart}
  }
  30% {
    ${giftBoxActiveShadowEnd}
    ${giftBoxActiveRiseEnd}
  }
  60% {
    ${giftBoxActiveRiseStart}
    ${giftBoxActiveShadowStart}
  }
`;

const GiftBoxButton = styled(Button).attrs({
  variant: 'unstyled',
  labelStyle: css`
    max-width: 100%;
  `,
})`
  flex: 1 1 auto;
  transition: transform 0.4s;
  outline: none;

  @media not (hover: none) {
    &:hover {
      ${giftBoxActiveRiseEnd}
    }

    .GiftBoxIconGroup {
      transition: filter 0.4s;
      ${giftBoxActiveShadowStart}
    }

    &:hover .GiftBoxIconGroup {
      ${giftBoxActiveShadowEnd}
    }
  }

  @media (hover: none) {
    animation-name: ${giftboxActivationAnimation};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    ${Array.from({ length: GIFT_BOX_COUNT }).map(
      (_, index) => css`
        &:nth-child(${index + 1}) {
          animation-delay: ${index * 1}s;
        }
      `
    )}
  }
`;

const GiftBoxOpeningWrapper = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  aspect-ratio: 441 / 444;
  ${mobile`
    width: calc(100% + 32px);
    margin-inline: -16px;
  `}
`;

const TopSubheader = styled(SubHeader)``;
const BottomSubheader = styled(SubHeader)``;
const LogoIcon = styled(BaseLogoIcon)`
  margin-inline: auto;
  margin-top: 24px;
  ${mobile`
    margin-top: 64px;
  `}
`;

const PromoHeaderWrapper = styled.div`
  text-transform: uppercase;
  display: grid;
  transition-property: gap, opacity, margin;
  transition-duration: 1s;
  z-index: 2;
  opacity: ${({ $view }) => ($view === Views.OPENING ? 0 : 1)};

  ${({ $isExpanded }) =>
    containedSizeStyle({
      'margin-top': 36,
      gap: $isExpanded ? 171 : 24,
    })}

  ${mobile`
    ${({ $isExpanded }) =>
      containedSizeStyle({
        'margin-top': $isExpanded ? 72 : 40,
        gap: $isExpanded ? 131 : 12,
      })}
  `}

  ${Header} {
    ${containedSizeStyle(70)}
    line-height: ${64 / 70};
    ${({ $isExpanded }) =>
      $isExpanded &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 3lh;
      `}

    ${mobile`
      ${containedSizeStyle(52)}
      line-height: ${46 / 52};
    `}
  }

  ${SubHeader} {
    font-weight: 700;
    text-transform: uppercase;
    ${containedSizeStyle(34)}
    ${mobile`
      line-height: ${27 / 34};
    `}
  }
`;

const Debug = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);

  h4 {
    font-size: 12px;
    text-transform: uppercase;
    align-self: center;
  }
`;

const DebugButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  padding: 0.25em 0.5em;
  /* FIXME: This is a hack for styling that is used throughout the app */
  > span {
    font-size: 12px;
  }
  text-decoration: none;
  ${({ $isActive }) =>
    $isActive &&
    css`
      > span {
        text-decoration: underline;
        font-weight: bold;
      }
    `}
`;

function PromoPicker() {
  const { gender } = useMarketingDisposition();
  const [view, setView] = useState(INITIAL_VIEW);
  const [isPaused, setIsPaused] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (view === Views.BOXES) {
      dispatch(
        trackPromoPickerPageView({
          pageName: buildVirtualPageRoute(VirtualPageNames.INTRO),
        })
      );
    }
  }, [view, dispatch]);

  // RUN-TIME FEATURE FLAG. Will not ever exist in FeatureManager;
  // must be opted-in via ?fm_override=promo_picker_debug
  const showDebug = useHasFeature(featureNames.PROMO_PICKER_DEBUG);

  const completeHeaderAnimation = useCallback(
    (event) => {
      debug('completeHeaderAnimation', view, event);
      if (isPaused) {
        return;
      }
      if (view === Views.CLOSING) {
        setView(Views.FORM);
      }
      if (view === Views.OPENED) {
        setTimeout(() => {
          setView(Views.CLOSING);
        }, OPENED_PAUSE_DURATION);
      }
    },
    [isPaused, view]
  );

  const onGiftBoxProgress = useCallback(
    (animation) => {
      if (isPaused) {
        return;
      }

      const { currentFrame, totalFrames } = animation;
      const progress = (currentFrame / totalFrames) * 100;
      const isThresholdMet = progress >= GIFT_BOX_ANIMATION_THRESHOLD;

      if (isThresholdMet) {
        debug('onGiftBoxProgress:thresholdMet', {
          animation,
          progress,
          isThresholdMet,
        });
        setView(Views.OPENED);
      }
    },
    [isPaused]
  );

  const handleFormViewChange = useCallback(
    (newView) => {
      let subPageName = '';
      switch (newView) {
        case PromoPickerFormViews.PROFILE_SIZES:
          subPageName = VirtualPageNames.SIZES;
          break;
        case PromoPickerFormViews.SIGNUP:
          subPageName = VirtualPageNames.SIGNUP;
          break;
        case PromoPickerFormViews.LOGIN:
          subPageName = VirtualPageNames.LOGIN;
          break;
      }
      if (subPageName) {
        dispatch(
          trackPromoPickerPageView({
            pageName: buildVirtualPageRoute(subPageName),
          })
        );
      }
      const hideHeader = newView === PromoPickerFormViews.LOGIN;
      setHideHeader(hideHeader);
    },
    [dispatch]
  );

  const { isMobile } = useBreakpoint();

  const logoProps = isFabletics()
    ? { size: 60 }
    : { height: isMobile ? 80 : 108, width: isMobile ? 100 : 136 };

  return (
    <>
      <PromoPickerContainer data-autotag="PromoPickerContainer">
        <PromoPickerWrapper $view={view}>
          {!hideHeader && view === Views.BOXES && (
            <>
              <LogoIcon {...logoProps} />
              <Header>
                <FormattedMessage
                  id="ppp.gift_header"
                  defaultMessage="A Gift Just For You"
                />
              </Header>
              <SubHeader>
                <FormattedMessage
                  id="ppp.gift_subheader"
                  defaultMessage="Tap a gift box to unwrap your exclusive discount!"
                />
              </SubHeader>
            </>
          )}
          {!hideHeader && view !== Views.BOXES && (
            <PromoHeaderWrapper
              onTransitionEnd={completeHeaderAnimation}
              $view={view}
              $isExpanded={view === Views.OPENING || view === Views.OPENED}
            >
              <TopSubheader data-autotag="PromoPicker__CongratsText">
                <FormattedMessage
                  id="ppp.promo_subheader_top"
                  defaultMessage="Congrats!"
                />
              </TopSubheader>
              <Header data-autotag="PromoPicker__Header__OfferText">
                <PromoOfferMessage />
              </Header>
              <BottomSubheader data-autotag="PromoPicker__BottomSubheader">
                <FormattedMessage
                  id="ppp.promo_subheader"
                  defaultMessage="New VIPs Only"
                />
              </BottomSubheader>
            </PromoHeaderWrapper>
          )}
          <ContentArea>
            {view === Views.BOXES && (
              <GiftBoxesWrapper data-autotag="PromoPicker__GiftBoxWrapper">
                {Array.from({ length: GIFT_BOX_COUNT }).map((_, index) => (
                  <GiftBoxButton
                    key={index}
                    onClick={() => setView(Views.OPENING)}
                    data-autotag={`PromoPicker__GiftBoxButton_${index}`}
                  >
                    <GiftBoxIcon color={theme.colors.promoPickerGiftBoxColor} />
                  </GiftBoxButton>
                ))}
              </GiftBoxesWrapper>
            )}
            {(view === Views.OPENING || view === Views.OPENED) && (
              <GiftBoxOpeningWrapper>
                <GiftBoxOpening onEnterFrame={onGiftBoxProgress} />
              </GiftBoxOpeningWrapper>
            )}
            {view === Views.FORM && (
              <SizeSelectionProvider gender={gender}>
                <PromoPickerFormPanels onViewChange={handleFormViewChange} />
              </SizeSelectionProvider>
            )}
          </ContentArea>
        </PromoPickerWrapper>
      </PromoPickerContainer>

      {showDebug && (
        <Debug>
          <h4>PPP</h4>
          <DebugButton onClick={() => setIsPaused(!isPaused)}>
            [{isPaused ? 'Paused' : 'Playing'}]
          </DebugButton>
          |
          <DebugButton
            $isActive={view === Views.BOXES}
            onClick={() => setView(Views.BOXES)}
          >
            1: Boxes
          </DebugButton>
          <DebugButton
            $isActive={view === Views.OPENING}
            onClick={() => setView(Views.OPENING)}
          >
            2: Opening
          </DebugButton>
          <DebugButton
            $isActive={view === Views.OPENED}
            onClick={() => setView(Views.OPENED)}
          >
            3: Opened
          </DebugButton>
          <DebugButton
            $isActive={view === Views.CLOSING}
            onClick={() => setView(Views.CLOSING)}
          >
            4: Closing
          </DebugButton>{' '}
          <DebugButton
            $isActive={view === Views.FORM}
            onClick={() => setView(Views.FORM)}
          >
            5: Form
          </DebugButton>
        </Debug>
      )}
    </>
  );
}

export default PromoPicker;
