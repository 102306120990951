import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { ExperienceIds } from '~/acquisition/constants/experienceIds';
import { getSizes } from '~/shared/utils/sizeConstants';
import useGender from '~/shared/utils/useGender';
import { Gender } from '~/techstyle-shared/react-accounts';
import { useMarketingDisposition } from '~/techstyle-shared/react-marketing';
import { useDomain, useSession } from '~/techstyle-shared/redux-core';

import { apparelOptions, SizeTypes } from '../constants';

const Context = createContext();

export const useSizeSelectionContext = () => useContext(Context);

/**
 * @function getSizeSelectionDefaults
 * @description Provides default values for size selection types and supports unique case
 * for size selection not supporting bra sizes.
 * @param {Boolean} hasBraSizeSelection Whether to support bra size selection
 * @returns {Object} The default size selection values
 */
function getSizeSelectionDefaults(hasBraSizeSelection) {
  const sizeSelectionDefaults = {
    [SizeTypes.BOTTOM_SIZE]: null,
    [SizeTypes.TOP_SIZE]: null,
  };

  return !hasBraSizeSelection
    ? sizeSelectionDefaults
    : { ...sizeSelectionDefaults, [SizeTypes.BRA_SIZE]: null };
}

/**
 * @function getSizeSelectionData
 * @description Takes an array of tuples and generates size selection data upon for
 * use upon registration.
 * @param {Array} dataTuples Size selection data in the form of an array of tuples
 * @returns {Object} The users size selection data
 */
function getSizeSelectionData(dataTuples) {
  return dataTuples.reduce((acc, [key, answer]) => {
    if (answer) {
      acc[key] = answer.value ? answer.value : answer;
    }

    return acc;
  }, {});
}

const SizeSelectionProvider = ({ gender: genderFromProps, children }) => {
  const { gender: genderDetail } = useGender();
  const gender = genderFromProps || genderDetail;
  const domain = useDomain();
  const { sessionId } = useSession();
  const disposition = useMarketingDisposition();
  const experienceId = disposition?.experience;

  const hasBraSizeSelection =
    gender === Gender.FEMALE && experienceId !== ExperienceIds.SCRUBS;

  // answers are saved in local storage and parsed out in the event
  // a user refreshes the page or exits and re-enters the size selection
  const sizeSelectionsKey = `sizeSelections__${sessionId}`;
  const savedAnswers = localStorage.getItem(sizeSelectionsKey);
  const [answers, setAnswers] = useState(
    savedAnswers ? JSON.parse(savedAnswers) : {}
  );

  // when used in a form, `isSelectionComplete` assists in disabling the
  // associated action button to proceed
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  useEffect(() => {
    if (
      (!answers?.customerInfo?.topSize &&
        apparelOptions.has(SizeTypes.TOP_SIZE)) ||
      (!answers?.customerInfo?.braSize &&
        hasBraSizeSelection &&
        apparelOptions.has(SizeTypes.BRA_SIZE)) ||
      (!answers?.customerInfo?.bottomSize &&
        apparelOptions.has(SizeTypes.BOTTOM_SIZE))
    ) {
      setIsSelectionComplete(false);
    } else {
      setIsSelectionComplete(true);
    }
  }, [answers, hasBraSizeSelection]);

  /**
   * @function updateSizeSelections
   * @description Spreads new size selection into pre-existing size selections, updating both
   * state and local storage.
   * @param {Object} sizeSelections The users current size selections
   */
  const updateSizeSelections = useCallback(
    (sizeSelections) => {
      const newCustomerInfo = {
        customerInfo: {
          ...sizeSelections,
        },
      };
      const updatedSizes = { ...answers, ...newCustomerInfo };

      setAnswers(updatedSizes);
      localStorage.setItem(sizeSelectionsKey, JSON.stringify(updatedSizes));
    },
    [answers, sizeSelectionsKey]
  );

  /**
   * @constant sizeChart
   * @description Provides the size chart for a given domain (region) and gender, supporting
   * the unique configuration for male tops.
   */
  const sizeChart = useMemo(() => {
    const args = { domain, gender };

    const sizes = {
      apparel: getSizes(args),
    };

    if (gender === Gender.MALE) {
      args.category = 'top-size';
      sizes.topSize = getSizes(args);
    }

    return sizes;
  }, [domain, gender]);

  /**
   * @function getSizeChartByType
   * @description Provides the size chart for a given size type
   * @param {SizeTypes} sizeType The size type to obtain the size chart of
   * @returns {Object} Collection of sizes for the given type of product by domain
   * and gender
   */
  const getSizeChartByType = useCallback(
    (sizeType) => {
      if (gender === Gender.FEMALE) {
        return sizeChart.apparel;
      }

      return sizeType === SizeTypes.TOP_SIZE
        ? sizeChart.topSize
        : sizeChart.apparel;
    },
    [gender, sizeChart.apparel, sizeChart.topSize]
  );

  /**
   * @constant sizeSelectionValues
   * @description Provides any currently selected size selection values. Sizes default
   * to `null` first and then overwrites with saved answers from local storage.
   */
  const sizeSelectionValues = useMemo(() => {
    const defaults = {
      ...getSizeSelectionDefaults(hasBraSizeSelection),
    };
    if (answers && answers.customerInfo) {
      return {
        ...defaults,
        ...answers.customerInfo,
      };
    }
    return defaults;
  }, [answers, hasBraSizeSelection]);

  /**
   * @constant sizeData
   * @description Size selection data for use when submitting to backend.
   */
  const sizeData = {
    ...getSizeSelectionData([
      ['bra-size', answers?.customerInfo?.braSize],
      ['top-size', answers?.customerInfo?.topSize],
      ['bottom-size', answers?.customerInfo?.bottomSize],
    ]),
  };

  const value = useMemo(
    () => ({
      isSelectionComplete,
      getSizeChartByType,
      sizeSelectionValues,
      sizeData,
      updateSizeSelections,
      hasBraSizeSelection,
      gender,
    }),
    [
      isSelectionComplete,
      getSizeChartByType,
      sizeSelectionValues,
      sizeData,
      updateSizeSelections,
      hasBraSizeSelection,
      gender,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

SizeSelectionProvider.propTypes = {
  children: PropTypes.node,
  gender: PropTypes.string,
};

export default SizeSelectionProvider;
