import React from 'react';

import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { mobile } from '~/techstyle-shared/react-components';

const Fieldset = styled.fieldset`
  position: relative; /* for dropdown icon positioning */
  border: none;
  height: 100%;
`;

const Label = styled.label`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.error : theme.colors.textDeemphasized};
  pointer-events: none;
  position: absolute;
  padding: 7px 17px;
  top: 10px;
  font-size: 16px;
  left: 0;
  transition: 0.2s ease all;
`;

const Select = styled.select`
  ${({ theme }) => theme.input.defaultStyle}
  font-family: ${({ theme }) => theme.fontStacks.baseFamily};
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: ${({ theme }) => theme.borderRadiusInput};
  width: 100%;
  height: inherit;
  appearance: none;
  font-size: 14px;
  padding: 21px 16px 4px;
  color: ${({ theme }) => theme.colors.textDefault};
  font-weight: normal;

  ${mobile`
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  `}

  ${({ $hasError, theme }) =>
    $hasError &&
    css`
      padding: 20px 16px 3px;
      border: 2px solid ${({ theme }) => theme.colors.error};
      ${mobile`
        border-bottom: 2px solid ${({ theme }) => theme.colors.error};
      `}
      color: ${({ theme }) => theme.colors.error};
      option {
        color: ${({ theme }) => theme.colors.textDefault};
      }
      &:focus {
        border-color: ${theme.colors.error};
      }
    `}

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.focusColor};
    border-width: 2px;
    padding: 20px 15px 3px;

    & ~ ${Label} {
      color: ${({ theme }) => theme.colors.textEmphasized};
    }

    ${({ $hasError, theme }) =>
      $hasError &&
      css`
        padding: 19px 16px 2px;
        border-color: ${theme.colors.error};
      `}

    ${mobile`
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 21px 16px 3px;
      ${({ $hasError }) =>
        $hasError &&
        css`
          padding: 21px 16px 4px;
        `}
    `}
  }

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      & ~ ${Label} {
        top: 0;
        font-size: 12px;
      }
    `}
`;

const ChevronDown = styled.div`
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 15px;
`;

const Error = styled.span`
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 12px;
  font-size: 14px;
  transform-origin: top right;
  transform: scale(0.75);
  color: ${({ theme }) => theme.colors.error};
`;

const StyledSelect = ({
  children,
  className,
  error,
  label,
  id,
  value,
  onChange,
  name,
  ...rest
}) => {
  const displayProps = {
    $hasError: Boolean(error),
    $hasValue: Boolean(value),
  };
  return (
    <Fieldset {...displayProps}>
      <Select
        id={id}
        className={className}
        onChange={onChange}
        value={value}
        name={name}
        $hasError={Boolean(error)}
        $hasValue={Boolean(value)}
        {...displayProps}
        {...rest}
      >
        {children}
      </Select>
      <ChevronDown aria-hidden>
        <FaChevronDown size="11px" />
      </ChevronDown>
      <Label htmlFor={id} {...displayProps}>
        {label}
      </Label>
      {Boolean(error) && <Error {...displayProps}>{error}</Error>}
    </Fieldset>
  );
};
StyledSelect.ChevronDown = ChevronDown;
StyledSelect.Fieldset = Fieldset;
StyledSelect.Label = Label;
StyledSelect.Select = Select;

StyledSelect.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default StyledSelect;
