import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SignupForm from '~/acquisition/components/SignupForm';
import LoginSlidingPanels from '~/login/components/LoginSlidingPanels';
import { useSizeSelectionContext } from '~/shared/components/SizeSelectionExpansionPanel/context/SizeSelectionProvider';
import { Link } from '~/techstyle-shared/next-routes';
import { Button } from '~/techstyle-shared/react-components';
import { FormattedMessage, useIntl } from '~/techstyle-shared/react-intl';

import PromoPickerPanelTemplate from './PromoPickerPanelTemplate';
import SizingForm from './SizingForm';

export const FormViews = {
  PROFILE_SIZES: 0,
  SIGNUP: 1,
  LOGIN: 2,
};

const StepContinueButton = styled(Button)`
  width: 100%;
`;

const CtaText = styled.span`
  font-weight: 700;
  text-decoration: underline;
  margin-left: 1.5em;
`;

const TermsAndPrivacyLinks = styled.footer`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const StyledLink = styled.a`
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.promoPickerFormPanelsLink};
`;

const Divider = styled.span`
  align-self: stretch;
  min-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

const LoginButton = styled(Button)`
  width: 100%;
  ${({ theme }) => theme.promoPickerPopup.signInButton}
`;

const PromoPickerFormPanelsHeading = styled(LoginSlidingPanels.Heading)`
  ${({ theme }) => theme.promoPickerPopup.loginHeading}
`;

export default function PromoPickerFormPanels({ onViewChange }) {
  const { isSelectionComplete, sizeData } = useSizeSelectionContext();

  const [view, setView] = useState(FormViews.PROFILE_SIZES);

  const intl = useIntl();

  useEffect(() => {
    if (onViewChange) {
      onViewChange(view);
    }
  }, [onViewChange, view]);

  const handleContinue = () => {
    if (view === FormViews.PROFILE_SIZES) {
      return setView(FormViews.SIGNUP);
    }
  };

  if (view === FormViews.LOGIN) {
    return (
      <>
        <PromoPickerPanelTemplate id="promo_picker_login_page">
          <LoginSlidingPanels heading={PromoPickerFormPanelsHeading} />
        </PromoPickerPanelTemplate>
      </>
    );
  }

  if (view === FormViews.PROFILE_SIZES) {
    return (
      <PromoPickerPanelTemplate
        subheading={
          <FormattedMessage
            id="ppp.profile_sizes_heading"
            defaultMessage="What sizes best represent you?"
          />
        }
        actions={
          <StepContinueButton
            onClick={handleContinue}
            disabled={!isSelectionComplete}
            data-autotag="PromoPickerFormPanels__StepContinueButton"
          >
            <FormattedMessage
              id="ppp.continue_button_cta"
              defaultMessage="Next"
            />
          </StepContinueButton>
        }
      >
        <SizingForm />
      </PromoPickerPanelTemplate>
    );
  }

  return (
    <PromoPickerPanelTemplate
      subheading={
        <FormattedMessage
          id="ppp.signup_heading"
          defaultMessage="Create an account to claim your offer"
        />
      }
      actions={
        <>
          {view === FormViews.PROFILE_SIZES && (
            <StepContinueButton
              onClick={handleContinue}
              data-autotag="PromoPickerFormPanels__StepContinueButton"
            >
              <FormattedMessage
                id="ppp.continue_button_cta"
                defaultMessage="Next"
              />
            </StepContinueButton>
          )}
          <LoginButton
            variant="unstyled"
            onClick={() => setView(FormViews.LOGIN)}
            data-autotag="PromoPickerFormPanels__CtaText"
          >
            <FormattedMessage
              id="ppp.signin_cta_leadin"
              defaultMessage="Already a member? {ctaText}"
              values={{
                ctaText: (
                  <CtaText>
                    {intl.formatMessage({
                      id: 'ppp.signin_cta_text',
                      defaultMessage: 'Sign In',
                    })}
                  </CtaText>
                ),
              }}
            />
          </LoginButton>
        </>
      }
      footer={
        <TermsAndPrivacyLinks>
          <Link href="/terms">
            <StyledLink
              target="_blank"
              rel="noreferrer"
              data-autotag="PromoPickerFormPanels__TermsOfServiceLink"
            >
              <FormattedMessage
                id="ppp.terms_of_service"
                defaultMessage="Terms of Service"
              />
            </StyledLink>
          </Link>
          <Divider />
          <Link href="/privacy">
            <StyledLink
              target="_blank"
              rel="noreferrer"
              data-autotag="PromoPickerFormPanels__PrivacyLink"
            >
              <FormattedMessage
                id="privacy.page_title"
                defaultMessage="Privacy Policy"
              />
            </StyledLink>
          </Link>
        </TermsAndPrivacyLinks>
      }
    >
      <SignupForm
        signupSource="pop_up_reg"
        signupData={{
          profile: sizeData,
        }}
      />
    </PromoPickerPanelTemplate>
  );
}

PromoPickerFormPanels.propTypes = {
  onViewChange: PropTypes.func,
};
