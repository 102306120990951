import React from 'react';

import styled from 'styled-components';

import SizeSelectionExpansionPanel from '~/shared/components/SizeSelectionExpansionPanel';
import SizeSelectionContent from '~/shared/components/SizeSelectionExpansionPanel/components/SizeSelectionContent';
import useHasFeature, { featureNames } from '~/shared/utils/useHasFeature';

const SizingFormWrapper = styled.div`
  ${SizeSelectionContent.SizingGrid} {
    ${({ theme }) => theme.promoPickerPopup.sizingGrid}
  }
`;

export default function SizingForm() {
  /**
   * @variable {boolean} isSMSEnabled
   * @description evaluating featureNames.PROMO_PICKER_SMS here to start the test when in
   * FormViews.PROFILE_SIZES but before FormViews.SIGNUP renders so it doesn't cause a
   * re-render of the signup form upon evaluation and conditionally rendering components
   *
   * NOTE: must disable eslint here because the variable is not used in the component
   */
  // eslint-disable-next-line no-unused-vars
  const isSMSEnabled = useHasFeature(featureNames.PROMO_PICKER_SMS);

  return (
    <SizingFormWrapper data-autotag="SizingForm__SizingFormWrapper">
      <SizeSelectionExpansionPanel />
    </SizingFormWrapper>
  );
}
