import React from 'react';

import PropTypes from 'prop-types';

import { Bra, Leggings, Top, MensBottom, MensTop } from '~/icons/fabletics';
import { Gender } from '~/techstyle-shared/react-accounts';

import { SizeTypes } from '../constants';
import { useSizeSelectionContext } from '../context';

function ApparelIcon({ sizeType }) {
  const { gender } = useSizeSelectionContext();

  switch (sizeType) {
    case SizeTypes.BOTTOM_SIZE: {
      if (gender === Gender.MALE) {
        return <MensBottom />;
      }
      return <Leggings />;
    }

    case SizeTypes.TOP_SIZE: {
      if (gender === Gender.MALE) {
        return <MensTop />;
      }
      return <Top />;
    }

    case SizeTypes.BRA_SIZE: {
      return <Bra />;
    }
  }
}

ApparelIcon.propTypes = {
  sizeType: PropTypes.oneOf(Object.values(SizeTypes)),
};

export default ApparelIcon;
