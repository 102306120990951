import { css } from 'styled-components';

import { mobile } from '~/techstyle-shared/react-components';

/*
This set of helpers is intended to allow ease the use of container
queries when UX mockups dictate strict positioning requirements. The
core concept is to define a container with a responsive custom property
to dictate the "optimal" width of the container based on the design
and allow the developer to use the `px2cqi` function to convert pixel
values from the mocks into container query units, which utilize web-
platform standards for truly "responsive" sizing.
 */

/**
 * Container query helpers
 * @typedef {Object} ContainerQueryHelpers
 * @property {string} containerStyles - The container query styles.
 * @property {Function} containedSizeStyle - A function to create a contained size style with a fallback to pixel values.
 * @property {Function} px2cqi - A function to convert pixel values to container query units.
 */

/**
 * Create container query helpers for a specific container.
 * @param {Object} options
 * @param {string} options.containerName - The name of the container.
 * @param {number} options.mobileOptimalWidth - The optimal width of the container on mobile.
 * @param {number} options.desktopOptimalWidth - The optimal width of the container on desktop.
 * @param {string} [options.units='cqi'] - The units to use for the container query. Can be 'cqi' or 'cqw'.
 * @returns {Object} ContainerQueryHelpers
 */
export function createContainerQueryHelpers({
  containerName,
  mobileOptimalWidth,
  desktopOptimalWidth,
  units: defaultUnits = 'cqi',
}) {
  const containerStyles = css`
    container-type: inline-size;
    container-name: ${containerName};
    --container-width: ${desktopOptimalWidth};
    ${mobile`
      --container-width: ${mobileOptimalWidth};
    `}
  `;

  const px2cqi = (size, units = defaultUnits) => {
    return `calc(${size} / var(--container-width, ${desktopOptimalWidth}) * 100${units});`;
  };

  const containedSizeStyle = (sizeOrValues, property = 'font-size') => {
    const values = {
      ...(typeof sizeOrValues === 'number'
        ? { [property]: sizeOrValues }
        : sizeOrValues),
    };

    return css`
      ${Object.keys(values).map(
        (property) => `${property}: ${values[property]}px;`
      )}
      @supports (contain: inline-size) {
        ${Object.keys(values).map(
          (property) => `${property}: ${px2cqi(values[property])};`
        )}
      }
    `;
  };

  return {
    containerStyles,
    containedSizeStyle,
    px2cqi,
  };
}
