import styled, { css } from 'styled-components';

import { TextField } from '~/techstyle-shared/react-components';

const labelStyle = css`
  &[data-label-position='floating'],
  &[data-label-position='inside'] {
    margin: 0;
    padding: var(--input-padding);
    transform: none;
    transition: all 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    line-height: ${18 / 12};
    ${({ theme }) =>
      css`
        color: ${theme.colors.inactiveTextInput};
      `}

    ${({ hasFocus, hasValue }) =>
      (hasFocus || hasValue) &&
      css`
        padding-top: 9px;
        font-size: 12px;
      `}}

    ${({ hasError }) => hasError && 'color: var(--input-error-color);'}
  }
`;
const inputBoxStyle = css`
  border-radius: 0;
  padding: 0;
  margin-top: -1px;
  border: var(--input-border-width) solid var(--input-border-color);
  &[data-has-focus] {
    padding: 0;
    border-width: var(--input-border-width);
  }
  ${({ hasError }) => hasError && `border-color: var(--input-error-color);`}

  &:has([data-is-first-input]) {
    border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
  }
  &:has([data-is-last-input]) {
    border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
  }
`;

const inputStyle = css`
  padding: 23px var(--input-padding) 9px;
  [data-label-position='floating'] & {
    transform: none;
  }
`;

const SignupTextField = styled(TextField).attrs({
  inputStyle,
  inputBoxStyle,
  labelStyle,
})``;

export default SignupTextField;
